import {Backdrop, Box, CircularProgress, Modal, Snackbar} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
    addNewCustomReservationAction, filterMapInformation, findUserByEmailAction,
    getAllUsers, searchUsers, setUsersAction, submitAddReservation,
    submitAddWalkin
} from "../../../../store/BeachAdmin/BeachAdminActions";
import React, {useEffect, useState} from "react";
import PhoneInput from "react-phone-number-input";
import CloseIcon from '@mui/icons-material/Close';
import {Alert} from "@mui/lab";
import {ErrorMessage, Field, Formik} from "formik";
import * as yup from "yup";
import SearchUsersDropdown from "./SearchUsersDropdown";

const AddNewReservationCard = ({
                                   numberOfPeople,
                                   selectedUser,
                                   setSelectedUser,
                                   finishAdd,
                                   email,
                                   setEmail,
                                   lastName,
                                   setLastName,
                                   firstName,
                                   setFirstName,
                                   phoneNumber,
                                   setPhoneNumber,
                                   resetForm
                               }) => {
    const {
        addNewCustomReservation,
        selectedDate,
        map,
        users,
        beach,
        country,
        isLoadingRes
    } = useSelector((state) => state.beachAdmin)
    const dispatch = useDispatch()
    const [search, setSearch] = useState('')
    const [isUserSelected, setIsUserSelected] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [showNumberError, setShowNumberError] = useState()
    const [reservationInfo, setReservationInfo] = useState({
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: phoneNumber
    });
    const setupProfileSchema = yup.object().shape({
        first_name: yup.string().required("First Name is required"),
        last_name: yup.string().required("Last Name is required"),
        email: yup.string().email("Invalid Email"),
        phone_number: yup.string().required("Phone Number is required"),

    });


    useEffect(() => {
        const timer = setTimeout(function () {
            // setIsUserSelected(false)
            // setSelectedUser({})
            dispatch(searchUsers(search))
        }, 500)
        return () => clearTimeout(timer);
    }, [search]);


    const handleClose = () => {
        dispatch(addNewCustomReservationAction(false))
    }

    const findUserByEmail = () => {
        dispatch(findUserByEmailAction(selectedUser.email))
            .then((r) => {
                setIsUserSelected(true)
                setEmailError(false)
            })
    }

    // const handleAdd = (values, event) => {
    //     let data = map?.filter((item) => item.is_selected_to_add === true)
    //     let details = {}
    //     if (data.length !== parseInt(numberOfPeople)) {
    //         setShowNumberError(true)
    //         return;
    //     }
    //     if (selectedUser) {
    //         details.user_id = selectedUser.id
    //     } else {
    //         details = {...values}
    //         details.phone_number = details.phone_number.replace('+','')
    //     }
    //     dispatch(submitAddReservation(data, details, selectedDate))
    //         .catch((e) => {
    //             if (e && e.response.status === 422) {
    //                 setEmailError(true)
    //                 return;
    //             }
    //         })
    //     finishAdd()
    // }
    const [peopleError, setPeopleError] = useState(false);

    const handleAdd = (values) => {
        if (numberOfPeople === 0) {
            setPeopleError(true);
            return;
        }
        let data = map?.filter((item) => item.is_selected_to_add === true);
        const selectedSeats = data.length;

        if (parseInt(numberOfPeople, 10) !== selectedSeats) {
            setShowNumberError(true);
            return;
        }

        let details = {};
        if (selectedUser) {
            details.user_id = selectedUser.id;
        } else {
            details = {...values};
            if (details.phone_number && !details.phone_number.startsWith("+")) {
                details.phone_number = `+${details.phone_number}`;
            }
        }

        details.reservation_date = selectedDate;
        dispatch(submitAddReservation(data, details, selectedDate))
            .catch((e) => {
                if (e && e.response.status === 422) {
                    setEmailError(true);
                    return;
                }
            });
        finishAdd();
        resetForm();
        localStorage.removeItem('reservationFormData');
    };

    function isKeyEmpty(obj, key) {
        return obj.hasOwnProperty(key) && obj[key] === '';
    }

    const handleSearch = (e) => {
        setSearch(e)
        setEmailError(false)
    }

    return (
        <div>
            <div className="mt-3">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        {/*<SearchUsersDropdown handleSelect={selectUser} handleSearch={handleSearch}*/}
                        {/*                     users={users ?? []}/>*/}
                        {/*<input value={search} type={"text"} onChange={(e) => handleSearch(e) }*/}
                        {/*       className={"bg-primary-blue/40 text-white py-2 px-3 border-0 rounded-full placeholder-color"} placeholder={"Search by name or phone"}/>*/}
                    </div>
                </div>
                {/*{*/}
                {/*    isUserSelected &&*/}
                {/*    <div className="row">*/}
                {/*        <div className="col-md-12">*/}
                {/*            <div className="float-end me-4 mt-2">*/}
                {/*                <button onClick={clearFilter} className={"btn btn-primary"}*/}
                {/*                        style={{background: '#005662', border: '1px solid #005662'}}>Clear*/}
                {/*                </button>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}

                {/*{*/}
                {/*   ( users && users.length) > 0 &&*/}
                {/*    <div className="row justify-content-center mb-5 px-3 gap-2 mt-3">*/}
                {/*        {*/}
                {/*            users?.map((user, index) => (*/}
                {/*                <div*/}
                {/*                    className={"col-md-12 mt-3 searched-user " + (user.is_selected ? "searched-user-selected" : "")}*/}
                {/*                    onClick={() => selectUser(index)}>*/}
                {/*                    <div>*/}
                {/*                        {user.first_name} {user.last_name}*/}
                {/*                    </div>*/}
                {/*                    {user.phone_number &&*/}
                {/*                        <i>({user.phone_number})</i>*/}
                {/*                    }*/}
                {/*                </div>*/}
                {/*            ))*/}
                {/*        }*/}

                {/*    </div>*/}
                {/*}*/}

                {
                    !selectedUser ?
                        <>
                            <Formik
                                initialValues={reservationInfo}
                                validationSchema={setupProfileSchema}
                                onSubmit={(values) => {
                                    handleAdd(values)
                                }}>
                                {({handleSubmit, values, setValues}) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="">
                                            <p className="text-primary-dark-green">First Name</p>
                                            <Field
                                                name="first_name"
                                                className="text-primary-gray rounded-md shadow-2xl border-primary-gray/10 border p-2 my-1"
                                                type="text"
                                                placeholder="Enter name"
                                                value={values.first_name}
                                                onChange={(e) => {
                                                    setFirstName(e.target.value);
                                                    setValues({...values, first_name: e.target.value});
                                                }}
                                            />
                                            <ErrorMessage
                                                name="first_name"
                                                component="div"
                                                className="error"
                                            />
                                        </div>
                                        <div className="">
                                            <p className="text-primary-dark-green">Last Name</p>
                                            <Field
                                                name="last_name"
                                                className="text-primary-gray rounded-md shadow-2xl border-primary-gray/10 border p-2 my-1"
                                                type="text"
                                                placeholder="Last name"
                                                value={values.last_name}
                                                onChange={(e) => {
                                                    setLastName(e.target.value);
                                                    setValues({...values, last_name: e.target.value});
                                                }}
                                            />
                                            <ErrorMessage
                                                name="last_name"
                                                component="div"
                                                className="error"
                                            />
                                        </div>
                                        <div className={""}>
                                            <p className="text-primary-dark-green">Email (optional)</p>
                                            <Field
                                                name="email"
                                                className="text-primary-gray rounded-md shadow-2xl border-primary-gray/10 border p-2 my-1"
                                                type="email"
                                                placeholder="Enter Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <ErrorMessage
                                                name="email"
                                                component="div"
                                                className="error"
                                            />
                                        </div>

                                        <div className={""}>
                                            <p className="text-primary-dark-green">Enter Phone</p>
                                            <PhoneInput
                                                name={"phone_number"}
                                                defaultCountry={"LB"}
                                                placeholder={"Enter phone number"}
                                                className={"text-primary-dark-green rounded-md shadow-2xl border-primary-gray/10 border p-2 my-1"}
                                                value={values.phone_number}
                                                onChange={(phone) => {
                                                    setPhoneNumber(phone);
                                                    setValues({...values, phone_number: phone});
                                                }}/>
                                            <ErrorMessage
                                                name="phone_number"
                                                component="div"
                                                className="error"
                                            />
                                        </div>
                                        {
                                            emailError &&
                                            <>
                                                <div className="row mt-3 align-items-center justify-content-center">
                                                    <div className="col-md-12">
                                                        <div className="alert alert-danger" role="alert">
                                                            Email Taken.
                                                        </div>
                                                    </div>
                                                    <div className={"col-md-12"}>
                                                        <button onClick={findUserByEmail}
                                                                className={"btn btn-secondary col-12"}>Find User?
                                                        </button>
                                                    </div>
                                                </div>

                                            </>

                                        }
                                        {peopleError && (
                                            <p className="text-red-600 text-sm pt-2">The number of people cannot be 0 to
                                                add a reservation.</p>
                                        )}
                                        <button type="submit"
                                                className="bg-primary-dark-green text-white rounded-md px-4 py-2 w-full my-4 xl:text-lg md:text-xs"
                                        >
                                            Reserve Now
                                        </button>
                                    </form>
                                )}
                            </Formik>
                        </>
                        :
                        <>
                            <div className="py-2">
                                <p className="text-primary-dark-green text-md font-bold">First Name</p>
                                <input
                                    type="text"
                                    readOnly
                                    value={selectedUser.first_name}
                                    className="bg-white text-primary-gray border-2 rounded-md p-2 overflow-auto whitespace-nowrap overflow-x-scroll"
                                />
                            </div>
                            <div className="py-2">
                                <p className="text-primary-dark-green text-md font-bold">Last Name</p>
                                <input
                                    type="text"
                                    readOnly
                                    value={selectedUser.last_name}
                                    className="bg-white text-primary-gray border-2 rounded-md p-2 overflow-auto whitespace-nowrap overflow-x-scroll"
                                />
                            </div>
                            <div className="py-2">
                                <p className="text-primary-dark-green text-md font-bold">Email</p>
                                <input
                                    type="text"
                                    readOnly
                                    value={selectedUser.email}
                                    className="bg-white text-primary-gray border-2 rounded-md p-2 overflow-auto whitespace-nowrap overflow-x-scroll"
                                />
                            </div>
                            <div className="py-2">
                                <p className="text-primary-dark-green text-md font-bold">Phone Number</p>
                                <input
                                    type="text"
                                    readOnly
                                    value={selectedUser.phone_number}
                                    className="bg-white text-primary-gray border-2 rounded-md p-2 overflow-auto whitespace-nowrap overflow-x-scroll"
                                />
                            </div>
                            <button onClick={(event) => handleAdd(null, event)}
                                    className="bg-primary-dark-green text-white rounded-md px-4 py-2 w-full my-4"
                            >
                                Reserve Now
                            </button>
                        </>
                }
            </div>
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={showNumberError}
                      autoHideDuration={6000} onClose={() => setShowNumberError(false)}>
                <Alert onClose={() => setShowNumberError(false)} severity={'error'} sx={{width: '100%'}}>
                    The number of people should be equal to the number of selected seats.
                </Alert>
            </Snackbar>

            {isLoadingRes &&
                <Backdrop
                    open={true}
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            }
        </div>
    )
}
export default AddNewReservationCard
