import React, {useEffect, useRef, useState} from "react";
import {useDrag} from "react-dnd";
import {ItemTypes} from "../../ItemTypes";
import {useDispatch, useSelector} from "react-redux";
import {
    handleGenerateItem, openChangeNumberModalAction, openImageCaptureSection,
    setItemIsDraggingInsideAction,
    setSavedSectionsAction, setShowRotateModal,
    setUndoData
} from "../../store/Map/MapActions";
import {faRotateRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EditIcon from '@mui/icons-material/Edit';
import {setMultipleSelectedItems} from "../../store/MultipleSelect/MultipleSelectActions";
import {Snackbar, Alert} from "@mui/material";

export const DraggedItem = ({section, index}) => {
    const dispatch = useDispatch()
    const {savedSections} = useSelector((state) => state.map)
    const {
        userMultiSelecting,
        multipleSelectedItems
    } = useSelector((state) => state.multipleSelect)
    const [showRotate, setShowRotate] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarPosition, setSnackbarPosition] = useState({x: 0, y: 0});

    const [{isDragging}, drag] = useDrag({
        type: ItemTypes.ITEM,
        item: userMultiSelecting ? {data: multipleSelectedItems, currentItem: section} : {
            ...section,
            indexInArray: index
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const handleRotate = () => {
        dispatch(setShowRotateModal(true, section, index))
        // let rotateDegree = 90
        // if (section.rotateDegree) {
        //     rotateDegree = section.rotateDegree + 90
        // }
        // let data = [...savedSections]
        // data[index] = {...section, rotateDegree: rotateDegree}
        // dispatch(setSavedSectionsAction(data))
        // dispatch(setUndoData(data))
    }

    useEffect(() => {
        dispatch(setItemIsDraggingInsideAction(isDragging));
    }, [isDragging]);

    const handleGenerate = () => {
        if (!section.isCaptureImage) {
            dispatch(handleGenerateItem(section))
        }
    }
    const handleMultipleSelect = () => {
        if (userMultiSelecting && !section.isCaptureImage) {
            const data = savedSections.map((mapItem) =>
                mapItem === section ? {...mapItem, multiple_selected: !mapItem.multiple_selected} : mapItem
            );
            dispatch(setSavedSectionsAction(data))
            dispatch(setUndoData(data))

            const selectedItems = data.filter(item => item.multiple_selected === true)
            dispatch(setMultipleSelectedItems(selectedItems))
        }
        if (section.isCaptureImage) {
            dispatch(openImageCaptureSection(section, true, index))
        }
    }
    const handleItemClick = () => {
        dispatch(openChangeNumberModalAction(true, section, index))
    }

    return (
        <>
            <div
                ref={drag}
                style={{
                    position: 'absolute',
                    display: 'grid',
                    left: section?.startX,
                    top: section?.startY,
                    width: section.width ?? '50px',
                    height: section.height ?? '50px',
                    backgroundColor: "transparent",
                    cursor: 'pointer',
                    color: "black",
                    transform: section.rotateDegree ? `rotate(${section.rotateDegree}deg)` : '',
                    justifyContent: 'center',
                    zIndex: 10,
                    filter: section.multiple_selected ? 'invert(26%) sepia(89%) saturate(1583%) hue-rotate(95deg) brightness(96%) contrast(106%)' : '',

                }}
                onMouseOver={() => setShowRotate(true)}
                onMouseLeave={() => setShowRotate(false)}
                onClick={handleMultipleSelect}
                onDoubleClick={handleGenerate}
                onMouseEnter={(e) => {
                    if (section.item_number) {
                        setOpenSnackbar(true);
                        setSnackbarPosition({x: e.clientX, y: e.clientY});
                    }
                }}
            >

                <div style={{position: 'relative'}}>
                    <div style={{position: 'absolute', top: 0, left: 0}}>
                        {
                            showRotate && (
                                <FontAwesomeIcon onClick={() => handleRotate()} icon={faRotateRight}
                                                 style={{fontSize: 16, color: "black"}} className="text-center"/>
                            )
                        }
                    </div>
                    <div style={{position: 'absolute', top: -2, right: 0}}>
                        {
                            (showRotate && !section.isCaptureImage) && (
                                <EditIcon onClick={() => handleItemClick()}
                                          style={{fontSize: 16, color: "black"}} className="text-center"/>
                            )
                        }
                    </div>
                </div>
                {
                    section.image ?
                        <img src={section.image} height={section.width ?? '50px'} width={section.height ?? '50px'}/>
                        :
                        <h5>{section.title}</h5>
                }
            </div>
            {section.item_number && (
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={1000}
                    onClose={() => setOpenSnackbar(false)}
                    anchorOrigin={{vertical: "top", horizontal: "left"}}
                    sx={{
                        position: "absolute",
                        left: snackbarPosition.x,
                        top: snackbarPosition.y - 40,
                    }}
                >
                    <Alert severity="info">Seat {section.item_number}</Alert>
                </Snackbar>
            )}
        </>
    );
};
