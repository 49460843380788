import React from 'react';
import {useNavigate} from "react-router-dom";

const CartSidebar = ({isSidebarOpen, cartItems, setCartItems, setIsSidebarOpen}) => {
    const navigate = useNavigate();
    const incrementQuantity = (index) => {
        const newCartItems = [...cartItems];
        newCartItems[index].quantity += 1;
        setCartItems(newCartItems);
    };
    const decrementQuantity = (index) => {
        const newCartItems = [...cartItems];
        if (newCartItems[index].quantity > 1) {
            newCartItems[index].quantity -= 1;
        } else {
            newCartItems.splice(index, 1);
        }
        setCartItems(newCartItems);
    };
    const calculateTotal = () => {
        return cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
    };
    const handleCheckout = () => {
        const orderDetails = {
            items: cartItems,
            total: calculateTotal(),
        };
        localStorage.setItem('orderDetails', JSON.stringify(orderDetails));
        navigate('/order-checkout');
    };

    const handleBackToMenu = () => {
        setIsSidebarOpen(false)
    };
    return (
        isSidebarOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
                <div
                    className="bg-white w-full h-full md:w-96 md:max-h-[90vh] shadow-xl px-5 pt-6 pb-4 flex flex-col relative">

                    <div className="flex items-center justify-between">
                        <p className="text-xl text-primary-dark-green font-bold">Your Cart</p>
                    </div>
                    <button
                        onClick={() => setIsSidebarOpen(false)}
                        className="absolute top-0 right-5 text-2xl text-primary-orange"
                    >
                        x
                    </button>
                    {cartItems.length > 0 ? (
                        <div className="flex-1 pt-10 overflow-y-auto">
                            {cartItems.map((item, index) => (
                                <div key={index} className="flex items-center mb-4 py-2 gap-2">
                                    <img src={item.image} alt={item.name} className="rounded-lg w-24 h-24"/>
                                    <div className="flex flex-col justify-between text-primary-dark-green">
                                        <p className="text-sm">{item.name}</p>
                                        <p className="text-md pt-1">${item.price}</p>
                                        <div className="flex items-center gap-4 mt-2">
                                            <button
                                                onClick={() => decrementQuantity(index)}
                                                className="bg-primary-orange text-white px-2 w-6 h-5 rounded-full flex items-center justify-center"
                                            >
                                                -
                                            </button>
                                            <p>{item.quantity}</p>
                                            <button
                                                onClick={() => incrementQuantity(index)}
                                                className="bg-primary-orange text-white px-2 w-6 h-5 rounded-full flex items-center justify-center"
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div
                                className="text-md pt-5 font-archivo flex justify-between items-center text-primary-dark-green">
                                Total:
                                <p className=""> ${calculateTotal()}</p>
                            </div>
                        </div>
                    ) : (
                        <p className="text-primary-dark-green text-xl font-bold pt-3"></p>
                    )}
                    <div>
                        <button className="bg-primary-dark-green text-white w-full py-2 rounded-full"
                                onClick={handleBackToMenu}>
                            Back to menu
                        </button>
                    </div>
                    {cartItems.length > 0 && (
                        <div className="pt-4 mb-3">
                            <button className="bg-primary-orange text-white w-full py-2 rounded-full"
                                    onClick={handleCheckout}>
                                Checkout <span className="tex-sm">(${calculateTotal()})</span>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        )
    );
};

export default CartSidebar;
