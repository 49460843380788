import Footer from '../../../../components/Footer'
import React from "react";
import Header from "../../../../components/Header";

export const TermsConditions = () => {
    return (
        <>
            <Header hasBackground={true}/>
            <div className="container">
                <p className="text-primary-orange text-2xl pt-28">General terms and conditions</p>
                <p className="mt-4 text-lg">
                    Welcome to<a href="https://beachbeds.com/"
                                 className="text-primary-orange font-bold m-0 text-[17px]"> Beachbeds.com! </a>
                    These Terms and Conditions govern your use of our website and services. By accessing or using our
                    platform, you agree to abide by these terms.
                </p>
                <p className="pt-4 pb-2">
                    <p>1.Business Information</p>
                    <a href="https://beachbeds.com/"
                       className="text-primary-orange font-bold m-0 text-[17px]"> Beachbeds.com! </a> is a platform
                    where users can browse, select, and book specific seats or services at various beaches.
                </p>
                <p className="py-2">
                    <p> 2.Website Use:</p>
                    There are no restrictions on who can use our website. Users must comply with the rules and
                    regulations of each beach booked through our service.
                </p>
                <p className="py-2">
                    <p> 3.Account Creation:</p>
                    You must create an account to book seats, bungalows, or any service. During account creation, we
                    collect First Name, Last Name, Birthday, Gender, Email, and Phone Number. Information collected may
                    be shared with any of our partnered beaches and resorts for reservations and /or marketing purposes.
                </p>
                <p className="py-2">
                    <p>4.Purchases and Payments:</p>
                    Accepted payment methods include Visa, Mastercard, Apple Pay, Whish Money, and OMT. By making a
                    purchase, you authorize us to charge applicable fees to your chosen payment method.
                </p>
                <p className="py-2">
                    <p>5.Refund and Cancellation Policy:</p>
                    Refunds are available in the form of beach points for bookings cancelled at least one week (5 days)
                    in advance. Beach points issued by Beachbeds.com shall be used at any of its partnered resorts
                    through the website for future bookings. For bookings cancelled within 5 days of the start date, no
                    refunds are provided. However,<a href="https://beachbeds.com/"
                                                     className="text-primary-orange font-bold m-0 text-[17px]"> Beachbeds.com! </a>at
                    its sole discretion, issue compensation in beach points that can be used at any of its partnered
                    resorts for such cancellations. Please note that booking and transaction fees may be deducted from
                    any refund or beach points.
                </p>
                <p className="py-2">
                    <p>6.Beach Access Terms:</p>
                    Beachbeds.com acts solely as a booking service and does not own, operate, or manage the beaches
                    listed on our platform. Each beach reserves the right to enforce its own policies, including
                    admission rules, dress codes, and security measures. A booking through <a
                    href="https://beachbeds.com/"
                    className="text-primary-orange font-bold m-0 text-[17px]"> Beachbeds.com! </a> grants you permission
                    for beach access; however, this access remains subject to the beach’s regulations and may be revoked
                    by the venue without refund if its rules are violated. Beach management may also implement security
                    checks at their discretion. Security measures, including searches of belongings, may be conducted
                    upon entry to ensure compliance and safety.
                </p>
                <p className="py-2">
                    <p> 7.Intellectual Property:</p>
                    Use of our logos, intellectual property, or identity requires our written consent.
                </p>
                <p className="py-2">
                    <p> 8.Privacy Policy:</p>
                    Information on how we collect, use, and protect your personal data can be found in our Privacy
                    Policy.
                </p>
                <p className="py-2">
                    <p> 9.Governing Law:</p>
                    These Terms and Conditions are governed by the laws of Lebanon.
                </p>
                <p className="py-2">
                    10.Governing Law These Terms and Conditions are governed by the laws of Lebanon.
                </p>
                <p className="py-2">
                    <p> 11.Modifications to the T&C:</p>
                    We will notify users of any changes to these Terms and Conditions via email or in-app notification.
                    By using Beachbeds.com, you agree to these Terms and Conditions. If you have any questions or
                    concerns, please reach out using the contact us form on the website.
                </p>
                <div className="pt-5">
                    <p className="text-primary-orange text-2xl">Privacy Policy</p>

                    <p className="py-2">
                        <p> 1. Introduction</p>
                        Beachbeds.com ("we," "our," or "us") values your privacy and is committed to protecting your
                        personal data. This Privacy Policy explains how we collect, use, and share your information when
                        you use our website.
                    </p>
                    <ul className="py-2">
                        <p> 2. Information We Collect</p>
                        When you create an account or make a booking, we collect the following personal information:
                        <li>• Full Name</li>
                        <li>• Email Address</li>
                        <li>• Phone Number</li>
                        <li>• Gender</li>
                        <li>• Date of Birth</li>

                    </ul>
                    <ul className="py-2">
                        <p>3. How We Use Your Information</p>
                        <li>
                            • To Facilitate your bookings with partner beaches
                        </li>
                        <li>
                            • To Provide customer support and respond to inquiries
                        </li>
                        <li>
                            • To Improve our services and user experience
                        </li>
                        <li>
                            • To Communicate important updates or promotions
                        </li>
                    </ul>
                    <p className="py-2">
                        <p> 4. Sharing of Information</p>
                        To process your bookings, we may share your personal details with the beach venues where you
                        make reservations. These venues have their own privacy policies and are responsible for handling
                        your data once shared. We do not sell or rent your information to third parties.
                    </p>
                    <p className="py-2">
                        <p> 5. Data Security</p>
                        We implement industry-standard security measures to protect your personal data from unauthorized
                        access, loss, or misuse. However, no system is completely secure, and we cannot guarantee
                        absolute protection.
                    </p>
                    <ul className="py-2">
                        <p>6. Your Rights</p>
                        <li>
                            • Access, update, or delete your personal information
                        </li>
                        <li>
                            • Withdraw consent for marketing communications
                        </li>
                        <li>
                            • Request information on how your data is used
                        </li>
                        For any privacy-related requests, contact us at <a
                        href="mailto:support@beachbeds.com"
                        className="text-primary-orange font-bold m-0 text-[17px] ">support@beachbeds.com</a>
                    </ul>
                    <p className="py-2">
                        <p>7. Changes to This Policy</p>
                        We may update this Privacy Policy periodically. Any changes will be posted on this page, and we
                        will notify you of significant updates via email or platform notifications.
                        By using Beachbeds.com, you consent to the collection and processing of your data as described
                        in this Privacy Policy.
                    </p>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default TermsConditions;
