import React, {useState} from 'react'
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';
import PinDetails from "./PinDetails";
import './style.css'
import {useNavigate} from "react-router-dom";

const containerStyle = {
    width: '100vw',
    height: '100vh'
};

const center = {
    lat: 34.0013824,
    lng: 35.6478754,
};

function GoogleMaps(data) {
    const allBeaches = data.allBeaches
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBdV53yanfME9zvWTjqRgFab82-Bx82Wqg"
    })
    const [selectedMarker, setSelectedMarker] = useState()
    const [map, setMap] = React.useState(null)
    const onLoad = React.useCallback(function callback(map) {
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    const navigate = useNavigate()
    const selectBeach = (id) => {
        localStorage.setItem('selected_beach', id)
        navigate('/beach-info')
    }
    const handleGoBack = () => {
        navigate(-1);
    };
    return isLoaded ? (
        <div style={{position: 'relative'}}>
            <button className="absolute top-24 left-4 p-2 z-50 bg-white text-white rounded-full py-2 flex items-center" onClick={handleGoBack}>
                <img src="/svgs/close.svg"/>
            </button>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={12}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{
                    mapTypeId: 'roadmap', // Set the map type to 'roadmap' (removes satellite view)
                    disableDefaultUI: true, // Disable default UI controls
                    zoomControl: true, // Enable zoom control
                }}>
                {allBeaches.map((beach, index) => {
                    return (
                        <Marker
                            position={beach.position}
                            icon={{
                                url: '/pin-image.svg',
                            }}
                            onClick={() => setSelectedMarker(beach)}
                        >
                            {selectedMarker === beach && (
                                <PinDetails
                                    position={beach.position}
                                    onCloseClick={() => setSelectedMarker(null)}>
                                    <div className="container-fluid font-archivo ">
                                        <div className={"row"}>
                                            <div className="col-md-5 col-lg-5 col-12 p-0">
                                                <img className={"google-maps-indicator"} style={{borderRadius: '8px'}}
                                                     src={beach.thumbnail_image}/>
                                            </div>
                                            <div className="col-md-7 col-lg-7 col-12">
                                                <h5 className={"mt-3"}>{beach.name}</h5>
                                                <p className={"m-0"}>{beach.location}</p>
                                                <button onClick={() => selectBeach(beach.id)}
                                                        className={"primary-button-reversed mt-3"}>Book
                                                </button>
                                            </div>

                                        </div>

                                    </div>
                                </PinDetails>
                            )}
                        </Marker>
                    );
                })}
            </GoogleMap>
        </div>
    ) : <></>
}

export default React.memo(GoogleMaps)
