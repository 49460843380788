import React, { useState } from 'react';
import { Drawer, FormControlLabel, Checkbox, RadioGroup, Radio } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const Filters = ({ filterData, onApply, onReset, filtersLabel }) => {
    const [selectedFilters, setSelectedFilters] = useState({});
    const handleChange = (filterCategory, value) => {
        setSelectedFilters((prev) => ({
            ...prev,
            [filterCategory]: value,
        }));
    };

    const handleCheckboxChange = (filterCategory, value) => {
        setSelectedFilters((prev) => ({
            ...prev,
            [filterCategory]: {
                ...(prev[filterCategory] || {}),
                [value]: !prev[filterCategory]?.[value],
            },
        }));
    };

    const applyFilters = () => {
        const formattedFilters = Object.keys(selectedFilters).reduce((acc, category) => {
            const filterValue = selectedFilters[category];
            if (typeof filterValue === 'object') {
                acc[category] = Object.keys(filterValue).filter(key => filterValue[key]);
            } else {
                acc[category] = filterValue;
            }
            return acc;
        }, {});
        onApply(formattedFilters);
        filterData.toggleDrawer();
    };

    const resetFilters = () => {
        setSelectedFilters({});
        onReset();
    };

    const categoryTitle = {
        reservation_types: 'Type',
        reservation_status: 'Status',
        gender: 'Gender',
        age: 'Age',
        beach_points: 'Beach Points',
        tags: 'Tags',
    };

    return (
        <Drawer
            anchor={'left'}
            open={filterData.open}
            onClose={filterData.toggleDrawer}
            PaperProps={{
                style: {
                    width: 300,
                    background: 'white',
                    height: '100%',
                },
            }}
        >
            <div className="text-primary-dark-green p-8">
                <CloseIcon
                    style={{ color: '#00414A' }}
                    className="flex justify-content-end items-end cursor-pointer"
                    onClick={filterData.toggleDrawer}
                />
                <p className="text-2xl py-4 text-primary-dark-green text-center font-bold">Filters</p>
                <div className="flex flex-col justify-between">
                    {filterData.filters.map((filter) => (
                        <div key={filter.category} className="py-3">
                            <h2 className="font-bold pb-2">{categoryTitle[filter.category] || filter.category}</h2>
                            {filter.type === 'radio' && (
                                <RadioGroup
                                    value={selectedFilters[filter.category] || ''}
                                    onChange={(e) => handleChange(filter.category, e.target.value)}
                                    className=""
                                >
                                    {filter.options.map((option) => (
                                        <FormControlLabel
                                            key={option.value}
                                            value={option.value}
                                            control={
                                                <Radio sx={{ color: '#B0CBCE', '&.Mui-checked': { color: '#F96300' } }} />
                                            }
                                            label={filtersLabel[filter.category]?.[option.value] || option.label}
                                            className=""
                                        />
                                    ))}
                                </RadioGroup>
                            )}
                            {filter.type === 'checkbox' && (
                                <>
                                    {filter.options.map((option) => (
                                        <div
                                            key={option.value}
                                            className="flex items-center justify-content-between gap-2 cursor-pointer"
                                            onClick={() => handleCheckboxChange(filter.category, option.value)}
                                        >
                                            <div>
                                                <Checkbox
                                                    checked={!!selectedFilters[filter.category]?.[option.value]}
                                                    sx={{ color: '#B0CBCE', '&.Mui-checked': { color: '#F96300' } }}
                                                />
                                                {filtersLabel[filter.category]?.[option.value] || option.label}
                                            </div>
                                            <div>
                                                {filter.category === 'beach_points' && (
                                                    <EmojiEventsIcon
                                                        style={{ color: filterData.colors[filter.category][option.label] }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    ))}
                    <div className="flex flex-col">
                        <button type="button" className="bg-primary-dark-green text-white rounded-md py-2" onClick={applyFilters}>
                            Apply
                        </button>
                        <button type="button" className="text-primary-dark-green" onClick={resetFilters}>
                            Reset Filters
                        </button>
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

export default Filters;
