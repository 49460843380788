import React, {useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";

const BeachRestaurants = ({restaurants}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const beachId = queryParams.get("beach");

    const handleViewMenu = (restaurant) => {
        if (!beachId) {
            return;
        }
        const selectedRestaurant = restaurants.find(r => r.id === restaurant.id);

        if (selectedRestaurant && selectedRestaurant.beach_id) {
            const resolvedBeachId = Number(selectedRestaurant.beach_id);
            localStorage.setItem("beachId", resolvedBeachId);
        }

        navigate(`/view-menu?restaurantId=${restaurant.id}&beach=${beachId}`);
    };

    // useEffect(() => {
    //     if (filteredRestaurants.length === 1) {
    //         const restaurant = filteredRestaurants[0];
    //         localStorage.setItem('selectedRestaurant', JSON.stringify(restaurant));
    //         navigate(`/view-menu?restaurantId=${restaurant.id}`);
    //     }
    // }, [filteredRestaurants, navigate]);

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mx-20">
            {restaurants.map((restaurant) => (
                <div key={restaurant.id} className="bg-white/8 backdrop-blur-md rounded-xl shadow-lg p-8 mt-5">
                    <div className="flex flex-col items-center text-center">
                        <img src={restaurant.image} alt={restaurant.name} className="w-16 h-16 mb-4 rounded-lg" />
                        <h2 className="text-white text-xl font-semibold mb-6 pt-2">{restaurant.name}</h2>
                        <button
                            type="button"
                            onClick={() => handleViewMenu(restaurant)}
                            className="bg-primary-dark-green hover:bg-teal-700 text-white py-2 px-6 rounded-full text-sm w-full"
                        >
                            View Menu
                        </button>
                    </div>
                </div>
            ))}
        </div>
    )
}
export default BeachRestaurants;
