import Header from "../../../components/Header";
import React, {useEffect, useState} from "react";
import CartSidebar from "./CartSidebar";
import CheckIcon from '@mui/icons-material/Check';
import {useDispatch, useSelector} from "react-redux";
import {callWaiter, getRestaurantsMenu} from "../../../store/MenuBuilder/MenuBuilderAction";
import {useLocation} from "react-router-dom";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {Modal, Box} from '@mui/material';
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import {Snackbar, Alert} from "@mui/material";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
const Menu = () => {
    const query = useQuery();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [cartItems, setCartItems] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [seatNumber, setSeatNumber] = useState('');
    const dispatch = useDispatch();
    const restaurantId = query.get('restaurantId');
    const beachId = query.get('beach');

    const restaurants = useSelector((state) => state.menuBuilder.restaurants);

    const [restaurantData, setRestaurantData] = useState(null);
    const [loading, setLoading] = useState(true);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const filteredCategories = restaurantData?.categories?.length
        ? restaurantData.categories.filter((category) =>
            category.items?.some((item) => item.is_active === 1))
        : [];

    const handleAddToCart = (item, categoryImage) => {
        setIsSidebarOpen(true);
        const itemIndex = cartItems.findIndex(cartItem => cartItem.id === item.id);

        const image = item.image || categoryImage;

        if (itemIndex === -1) {
            setCartItems([...cartItems, {...item, quantity: 1, image}]);
        } else {
            const newCartItems = [...cartItems];
            newCartItems[itemIndex].quantity += 1;
            setCartItems(newCartItems);
        }
    };
    const getItemQuantity = (itemId) => {
        const item = cartItems.find(item => item.id === itemId);
        return item ? item.quantity : 0;
    };
    const handleOpenModal = () => setIsModalOpen(true);

    const validationSchema = Yup.object().shape({
        seatNumber: Yup.string()
            .required("Seat number is required"),
    });

    const handleCallWaiter = (values, {resetForm}) => {
        // const beachId = parseInt(localStorage.getItem("bag_beach_id"), 10);
        const beachId =localStorage.getItem('beachId')
        dispatch(callWaiter(values.seatNumber, beachId))
            .then(() => {
                setSnackbarMessage("Waiter has been called!");
                setSnackbarOpen(true);
                resetForm();
                setIsModalOpen(false);
            })
            .catch(() => {
                setSnackbarMessage("Failed to call the waiter. Please try again.");
                setSnackbarOpen(true);
            });
    };

    useEffect(() => {
        if (!restaurantId || !beachId) {
            setLoading(false);
            return;
        }

        if (restaurants.length > 0) {
            const restaurant = restaurants.find((r) => r.id === parseInt(restaurantId));
            if (restaurant) {
                setRestaurantData(restaurant);
                setLoading(false);
                return;
            }
        }

        dispatch(getRestaurantsMenu(beachId)).then((response) => {
            if (response && response.data) {
                const restaurant = response.data.find((r) => r.id === parseInt(restaurantId));
                if (restaurant) {
                    setRestaurantData(restaurant);
                }
            }
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        });

    }, [dispatch, restaurants, restaurantId, beachId]);

    return (
        <div>
            <Header hasBackground={false}/>
            <div
                className="relative bg-no-repeat bg-cover min-h-screen pt-32 pb-10 flex justify-center"
                style={{backgroundImage: `url(/menu-bg.jpg)`}}
            >
                <div className="container mx-auto flex">
                    <div className={`flex-1 ${isSidebarOpen ? 'pr-80' : ''}`}>
                        <div className="md:flex items-center justify-between">
                            <div>
                                <p className="sm:text-xl text-white text-lg font-light uppercase mb-2">
                                    {restaurantData?.name} Menu
                                </p>
                                <p className="border-b-2 border-primary-orange w-10 mb-4"></p>
                                <p className="text-white font-light mb-6">
                                    Click on the items you want to order, then proceed to check out and get <br/>
                                    your order asap.
                                </p>
                            </div>
                            <div className="flex gap-2 items-center">
                                <div className="flex items-center bg-primary-orange text-white px-3 py-1 rounded-full">
                                    <img src="/svgs/call-waiter.svg" alt="Call waiter"/>
                                    <button type="button" className="text-sm" onClick={handleOpenModal}>
                                        Call a Waiter
                                    </button>
                                </div>
                                <div className="flex items-center bg-primary-orange text-white px-3 py-1 rounded-full">
                                    <ShoppingCartIcon/>
                                    <button type="button" className="text-sm"
                                            onClick={() => setIsSidebarOpen(true)}>View
                                        your cart
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div>
                            {filteredCategories?.map((category, index) => (
                                <div className="md:grid grid-cols-2 mt-5" key={category.id}>
                                    <img
                                        src={category.image}
                                        alt={category.name}
                                        className={`w-full h-[350px] object-cover ${index % 2 === 1 ? 'order-last md:rounded-tr-2xl md:rounded-br-2xl max-md:rounded-tl-2xl max-md:rounded-tr-2xl' : 'order-first md:rounded-tl-2xl md:rounded-bl-2xl max-md:rounded-tl-2xl max-md:rounded-tr-2xl'}`}
                                    />
                                    <div
                                        className="bg-white/8 backdrop-blur-md md:rounded-tr-2xl md:rounded-br-2xl max-md:rounded-bl-2xl max-md:rounded-br-2xl shadow-lg md:p-10 max-md:p-3 overflow-y-scroll max-h-[350px]">
                                        <p className="text-white text-3xl font-bold font-archivo mb-8">{category.name}</p>

                                        {category.items.filter((item) => item.is_active === 1).map((item) => (
                                            <div className="mb-6" key={item.id}>
                                                <div
                                                    className="text-white grid grid-cols-[1fr_1fr_1fr] items-center gap-4">
                                                    <div className="flex items-center gap-2">
                                                        {getItemQuantity(item.id) > 0 ? (
                                                            <button
                                                                type="button"
                                                                className="bg-primary-dark-green rounded-full text-white text-sm w-6 h-6 flex items-center justify-center"
                                                            >
                                                                <CheckIcon className="text-xs"/>
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="button"
                                                                className="bg-primary-orange rounded-full text-white w-6 h-6 aspect-square flex items-center justify-center"
                                                                onClick={() => handleAddToCart(item, category.image)}
                                                            >
                                                                +
                                                            </button>
                                                        )}
                                                        <p className="font-semibold text-sm">{item.name}</p>
                                                    </div>
                                                    <div className="border-b-2 border-dashed border-white"></div>
                                                    <p className="font-semibold text-lg">${item.price}</p>
                                                </div>
                                                <p className="text-sm text-gray-300 ml-5">{item.description}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <CartSidebar
                        isSidebarOpen={isSidebarOpen}
                        cartItems={cartItems}
                        setCartItems={setCartItems}
                        setIsSidebarOpen={setIsSidebarOpen}
                    />
                </div>
                <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'white',
                            boxShadow: 24,
                            p: 4,
                            borderRadius: 2,
                            width: 400,
                        }}
                    >
                        <Formik
                            initialValues={{seatNumber: ''}}
                            validationSchema={validationSchema}
                            onSubmit={handleCallWaiter}
                        >
                            {({isSubmitting}) => (
                                <Form>
                                    <div className="mb-4">
                                        <h2 className="text-primary-dark-green text-xl font-bold pb-3">Call a
                                            Waiter</h2>
                                        <Field
                                            name="seatNumber"
                                            type="text"
                                            placeholder="Enter your seat number"
                                            className="border-[0.5px] border-primary-gray p-2 rounded-md w-full"
                                        />
                                        <ErrorMessage
                                            name="seatNumber"
                                            component="div"
                                            className="text-red-500 text-sm mt-1"
                                        />
                                    </div>
                                    <div className="flex justify-end mt-3">
                                        <button
                                            type="submit"
                                            className="bg-primary-orange px-3 py-2 text-white rounded-lg"
                                            disabled={isSubmitting}
                                        >
                                            Call Waiter
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Modal>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={() => setSnackbarOpen(false)}
                    anchorOrigin={{vertical: "top", horizontal: "right"}}
                >
                    <Alert
                        onClose={() => setSnackbarOpen(false)}
                        severity="success"
                        sx={{width: "100%"}}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </div>
        </div>
    )
}
export default Menu;
