import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {OrdersTableHeaders} from "../../../../constants/tableData";
import React, {useState, useCallback, useEffect} from "react";
import {format} from 'date-fns';
import axios from "axios";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {Backdrop, CircularProgress, TextField} from "@mui/material";
import MapIcon from '@mui/icons-material/Map';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {MobileDatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {DateRange} from 'react-date-range';
import {enUS} from 'date-fns/locale';
import Table from "../../components/Table";
import {changeOrderStatus} from "../../../../store/Dashboard/Orders/OrdersAction";

const theme = createTheme({
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&.MuiInputBase-colorPrimary.MuiInputBase-formControl': {
                        display: 'none',
                    },
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#00414A',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-colorPrimary.css-1e6y48t-MuiButtonBase-root-MuiButton-root': {
                        color: 'red',
                    },
                },
            },
        },
    },
});
const Orders = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [rows, setRows] = useState(OrdersTableHeaders.rows);
    const [selectedDate, setSelectedDate] = useState(null);
    const beachId = sessionStorage.getItem('beach_id');
    const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
    const [bothDatesSelected, setBothDatesSelected] = useState(false);

    const [dateRange, setDateRange] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection',
        }
    ]);

    const handleOkButtonClick = async (date) => {
        setIsDatePickerOpen(false);
        setSelectedDate(date);
        if (date) {
            const formattedDate = format(date, 'yyyy-MM-dd');
            await getReservations(formattedDate);
        }
    };
    const handleCloseCalendar = async () => {
        setIsDatePickerOpen(false);
    };

    const handleDateRangePickerOpen = () => {
        setIsDateRangePickerOpen(true);
    };
    const handleDatePickerOpen = () => {
        setIsDatePickerOpen(true);
    };

    const handleSelect = (ranges) => {
        const {startDate, endDate} = ranges.selection;
        setDateRange([{
            ...ranges.selection,
            startDate: startDate ? new Date(startDate) : null,
            endDate: endDate ? new Date(endDate) : null,
        }]);
        setBothDatesSelected(startDate && endDate);
    };

    const getReservations = async (date = '', filters = {}, startDate = '', endDate = '') => {
        setLoading(true);
        try {
            let params = {
                date: date || localStorage.getItem('selectedOrderDate'),
                start_date: startDate,
                end_date: endDate,
            };

            const response = await axios.get(`/beach/${beachId}/get-orders`, {params});


            const reservationData = response.data.data.map(order => ({
                id: order.id,
                orderId: order.id,
                seatNumber: order.seat_number,
                items: order.order_items
                    .map((orderItem) => `${orderItem.quantity}x ${orderItem.item.name}\n`),
                price: `$${order.order_items.reduce(
                    (total, item) => total + (item.quantity * parseFloat(item.item.price)), 0
                ).toFixed(2)}`,
                status: order.status || 'Pending',
                note: order.note || '-',
            }));

            setRows(reservationData);

        } catch (error) {
            console.error("Error fetching orders:", error);
        } finally {
            setLoading(false);
        }
    };
    const handleSubmitDateRange = async () => {
        const startDate = dateRange[0].startDate ? format(dateRange[0].startDate, 'yyyy-MM-dd') : '';
        const endDate = dateRange[0].endDate ? format(dateRange[0].endDate, 'yyyy-MM-dd') : '';

        await getReservations('', {}, startDate, endDate);
        setIsDateRangePickerOpen(false);
    };
    const handleClearDateRange = () => {
        setDateRange([{
            startDate: null,
            endDate: null,
            key: 'selection',
        }]);
    };
    useEffect(() => {
        getReservations();
    }, []);

    useEffect(() => {
        const date = localStorage.getItem('selectedOrderDate');
        if (date) {
            setSelectedDate(new Date(date));
            getReservations(date);
        }
    }, []);

    useEffect(() => {
        if (selectedDate) {
            localStorage.setItem('selectedOrderDate', format(selectedDate, 'yyyy-MM-dd'));
        }
    }, [selectedDate]);

    const mapOrders = () => {
        navigate('/map-orders')
    }
    const handleEditRow = useCallback(
        async ({id, field, value}) => {
            if (field === 'status') {
                try {
                    const row = rows.find(row => row.id === id);
                    if (!row) throw new Error('Row not found');

                    await dispatch(changeOrderStatus(row.orderId, value));
                    setRows((prevRows) =>
                        prevRows.map((row) =>
                            row.id === id ? {...row, [field]: value} : row
                        )
                    );
                } catch (error) {
                    console.error('Error updating order status:', error);
                }
            } else {
                setRows((prevRows) =>
                    prevRows.map((row) =>
                        row.id === id ? {...row, [field]: value} : row
                    )
                );
            }
        },
        [dispatch, rows]
    );
    return (
        <>
            {loading && (
                <Backdrop
                    open={true}
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
            )}
            <div className="container py-10">
                <p className="text-primary-dark-green font-archivo-bold text-xl pb-4">Orders</p>
                <div className="md:flex justify-content-end items-center">
                    {/* <div className="relative my-4">
                    <SearchIcon
                        style={{color: "#F96300"}}
                        className="absolute left-3 top-1/2 transform -translate-y-1/2"
                    />
                    <input
                        type="text"
                        value={search}
                        onChange={handleSearchChange}
                        className="pl-10 pr-10 py-2 border border-gray-300 rounded w-full"
                        placeholder="Search..."
                    />
                    {search && (
                        <button
                            onClick={handleClearSearch}
                            className="absolute right-3 top-3 transform -translate-y-1/2"
                        >
                            <ClearIcon/>
                        </button>
                    )}
                </div> */}

                    <div className="flex gap-3 items-center">
                        <div className="bg-[#F3F7F7] rounded-lg p-2">
                            <MapIcon className="cursor-pointer text-[#00414A]" onClick={mapOrders}/>
                        </div>
                        {/* <div className="bg-[#F3F7F7] rounded-lg p-2" onClick={openFilter}>
                        <FilterAltIcon className="cursor-pointer text-[#00414A]"/>
                    </div> */}
                        <div className="flex items-center gap-2">
                            <div className="bg-[#F3F7F7] rounded-lg p-2" onClick={handleDatePickerOpen}>
                                <CalendarMonthIcon className="cursor-pointer text-[#00414A]"/>
                            </div>
                            {selectedDate && (
                                <span>{format(selectedDate, 'MM/dd/yyyy')}</span>
                            )}
                            <ThemeProvider theme={theme}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <MobileDatePicker
                                        open={isDatePickerOpen}
                                        onAccept={handleOkButtonClick}
                                        onClose={handleCloseCalendar}
                                        value={selectedDate}
                                        // onChange={handleDateChange}
                                        renderInput={(params) => <TextField {...params} sx={{display: 'none'}}/>}
                                    />
                                </LocalizationProvider>
                            </ThemeProvider>
                        </div>

                        {/*<button type="button" className="underline text-sm text-primary-orange"*/}
                        {/*        onClick={handleExportExcel}>*/}
                        {/*    Export Excel*/}
                        {/*</button>*/}

                        {isDateRangePickerOpen && (
                            <div
                                className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
                                <div className="relative bg-white p-[20px] rounded-md">
                                    <button
                                        onClick={() => setIsDateRangePickerOpen(false)}
                                        className="text-primary-dark-green absolute top-0 right-[10px] bg-none border-0 text-lg cursor-pointer z-10 font-bold"
                                    >
                                        X
                                    </button>
                                    <div className="mt-3">
                                        <DateRange
                                            editableDateInputs={true}
                                            onChange={handleSelect}
                                            moveRangeOnFirstSelection={false}
                                            ranges={dateRange[0].startDate && dateRange[0].endDate ? dateRange : [{
                                                startDate: new Date(),
                                                endDate: new Date(),
                                                key: 'selection'
                                            }]}
                                            locale={enUS}
                                            rangeColors={['#00414A']}
                                            color={'#00414A'}
                                        />
                                        <button
                                            type="button"
                                            className="text-white rounded-md p-2 bg-primary-dark-green text-sm mx-4 flex flex-col justify-end items-end"
                                            onClick={handleSubmitDateRange}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* <Snackbar
                        open={!!errorMessage}
                        autoHideDuration={6000}
                        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    >
                        <Alert onClose={handleCloseSnackbar} severity="error">
                            {errorMessage}
                        </Alert>
                    </Snackbar> */}
                    </div>
                </div>
                {/*<div className="flex items-center gap-2 py-3">*/}
                {/*    <div className="bg-[#F96300]/10 rounded-lg p-2" onClick={handleDateRangePickerOpen}>*/}
                {/*        <CalendarMonthIcon className="cursor-pointer text-[#F96300]"/>*/}
                {/*    </div>*/}
                {/*    {dateRange[0].startDate && dateRange[0].endDate ? (*/}
                {/*        <span>{`${format(dateRange[0].startDate, 'MM/dd/yyyy')} - ${format(dateRange[0].endDate, 'MM/dd/yyyy')}`}</span>*/}
                {/*    ) : (*/}
                {/*        <span>Start-Date/End-Date</span>*/}
                {/*    )}*/}
                {/*</div>*/}
                <div className="flex justify-content-end items-center gap-2 py-3">
                    <div className="bg-[#F96300]/10 rounded-lg p-2" onClick={handleDateRangePickerOpen}>
                        <CalendarMonthIcon className="cursor-pointer text-[#F96300]"/>
                    </div>
                    {dateRange[0].startDate && dateRange[0].endDate ? (
                        <span>{`${format(dateRange[0].startDate, 'MM/dd/yyyy')} - ${format(dateRange[0].endDate, 'MM/dd/yyyy')}`}</span>
                    ) : (
                        <span>Start-Date/End-Date</span>
                    )}
                    <button
                        type="button"
                        className="text-primary-orange rounded-md px-2 underline text-sm"
                        onClick={handleClearDateRange}
                    >
                        Clear
                    </button>
                </div>

                <Table
                    columns={OrdersTableHeaders.columns}
                    rows={rows}
                    setRows={setRows}
                    onEditCellChangeCommitted={handleEditRow}
                    pageType="orders"
                    tags={[]}
                />
                {/* <Filters filterData={{...reservationsFilterData, open: isFilterOpen, toggleDrawer: toggleFilterSideBar}}
                     onApply={applyFilters} onReset={resetFilters} filtersLabel={filtersLabel}/> */}
            </div>
        </>
    )
}
export default Orders
